/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    p: "p",
    pre: "pre",
    code: "code",
    strong: "strong",
    h3: "h3",
    h4: "h4",
    em: "em",
    blockquote: "blockquote",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "SSH Reference"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "creating keys"), "\n", React.createElement(_components.li, null, "common errors"), "\n", React.createElement(_components.li, null, "ssh between machines"), "\n", React.createElement(_components.li, null, "handling ssh with git and different accounts"), "\n"), "\n", React.createElement(_components.p, null, "ssh into remote server"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "ssh <remote-username>@<remote_domain/ip>\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Examples")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "ssh root@216.125.129.17\n")), "\n", React.createElement(_components.p, null, "If a ssh key pair is found a new session will start. If not and the machine allows ssh access via user name and password a prompt for password will occur."), "\n", React.createElement(_components.h3, null, "Move File or Directories between Machines"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "scp <source> <destination>\n")), "\n", React.createElement(_components.h4, null, "Files"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "scp /path/to/file username@:host/path/to/destination\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "scp username@host:/path/to/file /path/to/destination\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "scp foo@193.169.1.215:~/foo ~/foo \n")), "\n", React.createElement(_components.h4, null, "Dir"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "scp -r /path/to/dir username@:host/path/to/destination\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "scp -r username@host:/path/to/dir /path/to/destination\n")), "\n", React.createElement(_components.h2, null, "Set-Up ssh Access"), "\n", React.createElement(_components.h3, null, "Generate Key"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ssh-keygen -t rsa\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Github Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, " ssh-keygen -t ed25519 -C \"your_email@example.com\"\n")), "\n", React.createElement(_components.p, null, "For a single service use the default file ", React.createElement(_components.code, null, ".ssh/id_rsa"), " but for multiple services follow the steps below."), "\n", React.createElement(_components.h3, null, "Generate SSH Key and Add It to Remote Machine"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "ssh-copy-id -i ~/.ssh/<name-public-key>.pub <server_user_name>@<ip_add_server>\n")), "\n", React.createElement(_components.h3, null, "Setting Up Unique SSH for Multiple Services"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Make a config")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "touch ~/.ssh/config\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example config")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "config")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "# contents of $HOME/.ssh/config\nHost github.com\n   IdentityFile ~/.ssh/github.key\nHost gitlab.com\n   IdentityFile ~/.ssh/gitlab.key\n")), "\n", React.createElement(_components.p, null, "Now when ", React.createElement(_components.code, null, "ssh-keygen -t rsa"), " asks for a file specify the file associated with the domain or service"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example key gen")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "Generating public/private ed25519 key pair.\nEnter file in which to save the key (/Users/<foo>/.ssh/id_ed25519): github.key\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ssh-keygen -t ed25519 -C <github-email>\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 note: if the file does not show up in ", React.createElement(_components.code, null, ".ssh/"), " using the full path should correct"), "\n"), "\n", React.createElement(_components.h3, null, "Add Host to known Hosts"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "ssh auth Error")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "\"The authenticity of host '216.128.129.16 (216.128.129.16)' can't be established.\nED25519 key fingerprint is SHA256:nnzspBxbguoVHYiPiPEL6smGCfyE2KRSMLmayeaH3Mw.\nThis key is not known by any other names\"\nAre you sure you want to continue connecting (yes/no/[fingerprint])? yes\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ssh-keyscan -H <ip.address> >> ~/.ssh/known_hosts\n")), "\n", React.createElement(_components.h3, null, "Add to Service"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "🔥 make sure to use the ", React.createElement(_components.code, null, ".pub"), " extension as this is where our public key is stored"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "pbcopy < ~/.ssh/github.key.pub\n")), "\n", React.createElement(_components.p, null, "Add this to the services we want to Authorize."), "\n", React.createElement(_components.h2, null, "Testing and Troubleshooting Connections"), "\n", React.createElement(_components.p, null, "Test ssh connection"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ssh -T git@github.com\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ssh -T git@github.com\n")), "\n", React.createElement(_components.p, null, "Verbose tst"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "ssh -Tv git@github.com\n")), "\n", React.createElement(_components.p, null, "We can also test a specific host from out ", React.createElement(_components.code, null, "~/.ssh/config")), "\n", React.createElement(_components.h2, null, "Use with Github, BitBucket, Gitlab etc"), "\n", React.createElement(_components.h3, null, "Cloning a new project"), "\n", React.createElement(_components.p, null, "Clone repos with the host name declared in ssh config 👇"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git clone git@work:username/my-work-project.git\ngit clone git@personal:username/my-personal-project.git\n")), "\n", React.createElement(_components.p, null, "For reference, this is the default behavior"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git clone git@bitbucket.org:username/my-work-project.git\ngit clone git@bitbucket.org:username/my-personal-project.git\n")), "\n", React.createElement(_components.h3, null, "Updating and existing repo"), "\n", React.createElement(_components.p, null, "To update and existing repo to push to a specific github account/profile. Navigate to the project ", React.createElement(_components.code, null, ".git/config"), " and add the ", React.createElement(_components.code, null, "Host"), " from ", React.createElement(_components.code, null, ".ssh/config"), " as the host in the ssh url."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, ".git/config")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "...\n[remote \"origin\"]\n   url = git@work-profile:username/work-project-two.git\n\n...\n")), "\n", React.createElement(_components.h2, null, "SSH Between Two Mac on LAN"), "\n", React.createElement(_components.h3, null, "Common Friction Points"), "\n", React.createElement(_components.p, null, "You can try the following but it can fail ", React.createElement(_components.code, null, "Full Disk Access privileges."), " error."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo systemsetup -setremotelogin on\n")), "\n", React.createElement(_components.p, null, "If 👆that doesn't work try 👇"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "## Enabling SSH\nsudo launchctl load -w /System/Library/LaunchDaemons/ssh.plist\n")), "\n", React.createElement(_components.p, null, "to turn remote access off as a security best practice."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "## Disabling SSH\nsudo launchctl unload /System/Library/LaunchDaemons/ssh.plist\n")), "\n", React.createElement(_components.h3, null, "Check Status"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "# To check if is on or off\nsudo systemsetup -getremotelogin\n")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.digitalocean.com/community/tutorials/how-to-configure-ssh-key-based-authentication-on-a-linux-server"
  }, "Set up ssh key auth between machines")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://nerderati.com/2011/03/17/simplify-your-life-with-an-ssh-config-file/"
  }, "how to set up ssh for multiple services")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://docs.github.com/en/authentication/connecting-to-github-with-ssh/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent"
  }, "Generating new ssh key and adding it to ssh-agent - github")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
